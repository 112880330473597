
import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import {
  exactRule,
  maxLengthRule,
  minLengthRule
} from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Create",

  data: () => ({
    exactRule,
    maxLengthRule,
    minLengthRule,
    model: {},
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  computed: {
    rules() {
      return {
        required: [
          (v: string): boolean | string | LocaleMessages =>
            !!v || this.$t("global_validation.required")
        ],
        min: this.minLengthRule.bind(this),
        max: this.maxLengthRule.bind(this)
      };
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.variables().create(this.model);
          await this.$router.push("/variables");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
