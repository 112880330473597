import { LocaleMessages } from "vue-i18n";
import i18n from "../../plugins/i18n";

export const exactRule = function(
  number: number,
  selectValidation = false
): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      !v ||
      v?.trim().length === number ||
      // @ts-ignore
      i18n.t("global_validation.exact", { number });
  }
  return (v: string | any): boolean | string | LocaleMessages =>
    !v ||
    v.text.trim().length === number ||
    // @ts-ignore
    i18n.t("global_validation.exact", { number });
};

export const minLengthRule = function(
  number: number,
  selectValidation = false
): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      !v ||
      (v && v.length >= number) ||
      // @ts-ignore
      i18n.t("global_validation.min", { number });
  }
  return (v: any): boolean | string | LocaleMessages =>
    !v ||
    (v && v.length >= number) ||
    (v && v.text.length >= number) ||
    // @ts-ignore
    i18n.t("global_validation.min", { number });
};

export const maxLengthRule = function(
  number: number,
  selectValidation = false
): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      // @ts-ignore
      !v ||
      v?.length <= number ||
      // @ts-ignore
      i18n.t("global_validation.max", { number });
  }
  return (v: any): boolean | any | LocaleMessages =>
    !v ||
    !v.text ||
    v.text.length <= number ||
    v.length <= number ||
    // @ts-ignore
    i18n.t("global_validation.max", { number });
};

export const requiredRule = function(
  field = "" as string,
  isSelect: any = false,
  mixSelect: any = false
): Function {
  if (mixSelect) {
    return (v: any): boolean | string | LocaleMessages => {
      if (v && v.hasOwnProperty("text")) {
        return (
          !!v.text ||
          // @ts-ignore
          i18n.t("global_validation.required_field", { field })
        );
      }
      return (
        !!v ||
        // @ts-ignore
        i18n.t("global_validation.required_field", { field })
      );
    };
  }

  if (isSelect) {
    return (v: any): boolean | string | LocaleMessages =>
      !!v.text ||
      // @ts-ignore
      i18n.t("global_validation.required_field", { field });
  }
  return (v: string): boolean | string | LocaleMessages =>
    !!v ||
    // @ts-ignore
    i18n.t("global_validation.required_field", { field });
};

export const minNumberRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    Number(v) >= number ||
    // @ts-ignore
    i18n.t("global_validation.min_number", { number });
};

export const maxNumberRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    Number(v) <= number ||
    // @ts-ignore
    i18n.t("global_validation.max_number", { number });
};

export const emailRule = function(
  v: any,
  selectValidation = false
): boolean | string | LocaleMessages {
  // const emailValidation = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  // const emailValidation = /^[^@]+@\w+(\.\w+)+\w$/;
  const emailValidation = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;

  if (!selectValidation) {
    return (
      !v ||
      emailValidation.test(v) ||
      // @ts-ignore
      i18n.t("global_validation.email")
    );
  }
  return (
    (!!v && emailValidation.test(v.text)) ||
    // @ts-ignore
    i18n.t("global_validation.email")
  );
};

export const minCountRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    v.length >= number ||
    // @ts-ignore
    i18n.t("global_validation.min_count", { number: number - 1 });
};

export const websiteRule = function(): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    /.\../.test(v) ||
    // @ts-ignore
    i18n.t("global_validation.email");
};

export const phoneRule = function(selectValidation = false): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      !v ||
      /^[0-9,]*$/.test(v) ||
      // @ts-ignore
      i18n.t("global_validation.phone");
  }
  return (v: any): boolean | string | LocaleMessages =>
    !v ||
    /^[0-9,]*$/.test(v.text) ||
    // @ts-ignore
    i18n.t("global_validation.phone");
};

export const uniqueRule = function(
  array: Array<any>,
  value: number,
  itemValue = "value"
): Function {
  return (): boolean | string | LocaleMessages =>
    array.reduce(
      (count, x: any) => (x[itemValue] === value ? count + 1 : count),
      0
    ) <= 1 ||
    // @ts-ignore
    i18n.t("global_validation.unique");
};

export const isEqualStringRule = function(
  string: string,
  locales = "global_validation.is_equal"
): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    string === v ||
    // @ts-ignore
    i18n.t(locales);
};

export const maxRowLengthRule = function(maxRowLength: number): Function {
  return (v: string): boolean | string | LocaleMessages => {
    const errorRowsCount = v
      ?.split("\n")
      .map((item: string, index: number) => {
        if (item.length > maxRowLength) {
          return index + 1;
        }
      })
      .filter((item: any) => item);

    return (
      !errorRowsCount?.length ||
      i18n.t("global_validation.max_row_length", {
        maxRowLength,
        errorRowsCount
      })
    );
  };
};

export const maxRowsRule = function(maxRows: number): Function {
  return (v: string): boolean | string | LocaleMessages => {
    const enterCount = v
      ?.split("")
      .map((item: string, index: number) => {
        if (v.charCodeAt(index) === 10) {
          return index;
        }
      })
      .filter((item: any) => item);

    return (
      enterCount?.length < maxRows ||
      i18n.t("global_validation.max_rows", { maxRows })
    );
  };
};

export default {
  maxRowLength: maxRowLengthRule,
  maxRows: maxRowsRule,
  required: requiredRule,
  website: websiteRule,
  minCount: minCountRule,
  phone: phoneRule,
  email: emailRule,
  minNumber: minNumberRule,
  maxNumber: maxNumberRule,
  min: minLengthRule,
  max: maxLengthRule,
  exact: exactRule,
  unique: uniqueRule,
  isEqualString: isEqualStringRule
};
